import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import axios from 'axios';

// Note: we use the /shared/anypoint-navbar from index.html in prod
import NavBar from '@mulesoft/anypoint-navbar'; /* eslint-disable-line import/no-extraneous-dependencies */

import environmentService from 'services/environmentService';
import {
  executeDeepLink,
  getDeepLinkParams
} from 'services/deepLinkService';
import accountService from 'services/accountService';

import loggerUtil from 'utils/logger';
import Profile from 'utils/Profile';
import {
  inIFrame,
  notifyProfileUpdated
} from 'utils/iframe';

import SidebarContainer from './Sidebar.container';
import styles from './styles.css';

const logger = loggerUtil('ShellUI');

/**
 * Renders the UI of the SPA (navbar, sidebar, and the SPA app) at the correct deep linkable path.
 */
class ShellUI extends React.Component {

  static propTypes = {
    /* The React component class to render (e.g. MQ root component) */
    app: PropTypes.func.isRequired,
    /* The path for the app e.g. /mq/. Used to compute the deep link */
    appPath: PropTypes.string.isRequired,
    /* React router history to update the URL with the correct deep link */
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    /* React router location to compare the current path with the computed deep link */
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
    /* whether or not the app needs an envSwitcher. */
    showEnvSwitcher: PropTypes.bool.isRequired,
    /* whether or not the app needs an orgSwitcher. */
    showOrgSwitcher: PropTypes.bool.isRequired,
    /* Callback for when the user signs out */
    onSignout: PropTypes.func,
    /* Determine if the Navbar needs to be shown (e.g. false for signin, true for most apps) */
    showNavBar: PropTypes.bool,
    /** {@link SpaContext} */
    spaContext: PropTypes.shape({
      profile: PropTypes.instanceOf(Profile),
      sidebar: PropTypes.shape({
        activeSidebarLink: PropTypes.shape({}),
      }),
      axios: PropTypes.shape({}),
      actions: PropTypes.shape({
        onUpdatedProfileData: PropTypes.func,

      }),
      environments: PropTypes.shape({}),
      activeEnvironmentId: PropTypes.shape({}),
      activeOrganizationId: PropTypes.shape({}),
      activeOrganizationIdions: PropTypes.shape({}),
      mountPath: PropTypes.shape({}),
    }),
  };

  static defaultProps = {
    onSignout: () => {},
    showNavBar: false,
    spaContext: null,
  };

  constructor(props) {
    super(props);
    const { app } = props;
    if (!app) {
      throw new Error('A react component must be provided for the "app" property');
    }
    this.state = {
      initialized: false,
      sidebarConfig: null, // the config for the root sidebar
      sectionConfig: null,  // the config for the sidebar when there's a back button to the root config,
      loadedApp: null,
      appName: null,
      appIcon: null
    };
    const { spaContext } = props;
    // Define callbacks for SPA apps to configure the sidebar.
    spaContext.sidebar = {
      initialize: (sidebarConfig, appName, appIcon) => {
        const hasSidebarElements = Array.isArray(sidebarConfig) && sidebarConfig.length > 0;
        if (!hasSidebarElements) {
          throw new Error('sidebar.initialize() must be an array with at least one sidebar element.');
        }
        this.setState({ sidebarConfig, appName, appIcon });
      },
      setSectionConfig: sectionConfig => this.setState({ sectionConfig }),
    };
  }

  async componentDidMount() {

    const { app: App } = this.props;
    const { orgId, envId } = getDeepLinkParams(this.props.location);
    this.goToDeepLink(orgId, envId)
      .then(() => this.setState({ initialized: true }));
    try {
      const loadedApp = await App;
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ loadedApp });
    } catch (e) {

    }
  }

  // eslint-disable-next-line class-methods-use-this
  componentDidCatch(error, errorInfo) {

  }

  /**
   * Call this when the user's profile has changed. E.g. when an organization has been renamed
   * or the user's name has been updated or an action has been taken that would affect the
   * user's list of organizations.
   */
  onUpdatedProfileData = async () => {
    if (inIFrame()) {
      // TODO: Navbar should watch this method and re-render
      notifyProfileUpdated();
      return;
    }
    const { spaContext } = this.props;
    const { profile }    = spaContext;
    if (!profile) {
      logger.error('onUpdatedProfileData should not be called when a user is not logged in.');
      return;
    }

    // Refresh the profile and apply the current access token - just to avoid
    // an unnecessary network round-trip.
    try {
      const updatedProfile        = await accountService.getProfileWithoutAccessToken();
      updatedProfile.access_token = profile.access_token;

      // This will cause the NavBar to rerender since it takes the profile as a prop:
      spaContext.profile = updatedProfile;

      // Hack: To force a rerender, since we are changing the profile, we throw the profile
      // into state. We don't use it since we pick it up from spaContext, for simplicity.
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        profile: updatedProfile
      });
    } catch (e) {
      // Silently fail since updating isn't critical
    }
  };

  setUpSpaContext() {
    const { spaContext } = this.props;
    // Expose the SPA's axios module preconfigured to add accessToken and handle unauthorized requests.
    // Other projects may have axios, but that axios project dependency will not have the defaults configured for the SPA
    // and may be an entirely different version of axios.
    spaContext.axios = axios;
    spaContext.actions.onUpdatedProfileData = this.onUpdatedProfileData;
  }

  // eslint-disable-next-line react/sort-comp
  async goToDeepLink(orgId, envId) {
    const {
      appPath,
      showOrgSwitcher,
      showEnvSwitcher,
      spaContext,
      history,
      location
    } = this.props;
    const { profile } = spaContext;


    return executeDeepLink(appPath, profile, showOrgSwitcher, orgId, showEnvSwitcher, envId)
      .then(async ({ correctedPath, updatedProfile }) => {

        // Don't get environments or update the spaContext orgId and envId if user is unauthenticated.
        // This occurs for the signin app.
        const isAuthenticated = !!updatedProfile;
        if (isAuthenticated) {
          const activeOrgId = updatedProfile.getActiveOrgId();
          // Add the current environments to the spaContext in an envSwitching app for the sidebar container
          spaContext.environments         = showEnvSwitcher ? await environmentService.getEnvironments(activeOrgId) : null;
          spaContext.activeOrganizationId = activeOrgId;
          spaContext.activeEnvironmentId  = updatedProfile.getActiveEnvId(spaContext.environments);

        }
        // The updatedProfile may be the original profile, but to keep things simple we always set it.
        spaContext.profile   = updatedProfile;
        spaContext.mountPath = correctedPath;


        // If the correctedPath does not match the current path it means the orgId and/or envId in the deep
        // link was unspecified or does not exist.
        const shouldUpdatePath = !location.pathname.startsWith(correctedPath);
        if (shouldUpdatePath) {
          // On org & env switches, keep the previous active sidebar link by appending it to the mountPath.
          const shouldAppendActiveSidebarLink = showEnvSwitcher && spaContext.sidebar.activeSidebarLink;
          const updatedPath = shouldAppendActiveSidebarLink ? correctedPath + spaContext.sidebar.activeSidebarLink : correctedPath;

          history.push(updatedPath);
        }
      });
  }

  handleOrgChange = activeOrg => {

    this.goToDeepLink(activeOrg.id, null);
  };

  handleEnvChange = activeEnvId => {

    const { spaContext, location } = this.props;
    spaContext.profile.setActiveEnvId(activeEnvId);
    const { orgId } = getDeepLinkParams(location);
    this.goToDeepLink(orgId, activeEnvId);
  };

  /**
   * @return {*} the NavBar.
   */
  renderNavBar = () => {
    const {
      showNavBar,
      onSignout,
      spaContext,
      showOrgSwitcher
    } = this.props;
    const { profile } = spaContext;
    const shouldShowNavbar = showNavBar && spaContext && profile;
    if (!shouldShowNavbar) {
      return null;
    }
    return (
      <NavBar
        activeOrganizationId={profile.getActiveOrgId()}
        showBusinessGroups={showOrgSwitcher}
        onOrganizationChange={this.handleOrgChange}
        onSignout={onSignout}
        spaContext={spaContext}
        profile={profile}
      />
    );
  };

  renderSidebar = () => this.state.sidebarConfig && (
    <SidebarContainer
      spaContext={this.props.spaContext}
      showEnvSwitcher={!!this.props.showEnvSwitcher}
      sidebarConfig={this.state.sidebarConfig}
      sectionConfig={this.state.sectionConfig}
      onEnvironmentChange={this.handleEnvChange}
      appName={this.state.appName}
      appIcon={this.state.appIcon}
    />
  );

  renderContents() {
    const { spaContext } = this.props;
    const { loadedApp: App } = this.state;
    const immutableSpaContext = Object.assign({}, spaContext);

    // We give SPA apps an immutable spaContext by freezing a copy of the spaContext.
    // This prevents SPA apps from changing properties on the spaContext unintentionally or otherwise.
    Object.freeze(immutableSpaContext);
    // If the SPA is rendered in an iframe, dont render "chrome" (i.e. the navbar and sidebar)
    // since we expect it'll be in an app that already has it.
    const renderChrome = !inIFrame();
    return (
      <React.Fragment>
        { renderChrome && this.renderNavBar() }
        <div className={styles.application}>
          { renderChrome && this.renderSidebar() }
          <div id="appRoot" className={styles.appRoot}>
            {/*
              Instead of having the app determine its actions based on the spaContext,
              we effectively throw away the old SPA app component and create a new one
              each time the necessary context for the application changes.
              Instead of just a re-render, the component will be destroyed
              and a new instance of the component will be created.

              This means it goes through the full lifecycle of construction,
              mounting, rendering, unmounting, etc.

              The key factor for determining this is the SPA mountPath.
              Given that we have deep links, links which contain the state
              of the app, the context for the app is the link itself.
              Let's look at a few examples of this.
              - An app requiring organization and environment switching such as MQ
                will have a mountPath of /mq/organizations/{org_id}/environments/{env_id}
                that changes to update with the active organizationId and active environmentId.
              - An app such as signin does not require organization or
                environment switching, and thus will have /signin as the mountPath,
                indicating that the component never needs to be re-initialized.
              - An app such as the landing page only requires organization
                switching, and thus will have a mountPath of
                /home/organizations/{org_id}, which will update every time an
                organization switch is made.

               The key prop determines if the App component needs to be re-initialized.
               See h
               ttps://reactjs.org/docs/lists-and-keys.html#keys
               */}
            <Switch>
              {/*
                    * Ensure that a url like "/home" gets redirected to "/home/organizations/:orgId". In other words, make sure that
                    * the url the user is at is always a deep link containing at least an organization (except in sign-in.)
                    */}
              <Route path={spaContext.mountPath} render={() => <App key={spaContext.mountPath} spaContext={immutableSpaContext} />} />
              <Route>
                <Redirect to={spaContext.mountPath} />
              </Route>
            </Switch>
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {

    this.setUpSpaContext();
    const { initialized } = this.state;
    if (initialized) {
      return this.renderContents();
    }
    return null;
  }

}

export default ShellUI;
